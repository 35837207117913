.container {
    /* min-height: 100vh !important; */
    padding: 50px;
}

.submit-btn {
    padding: 12px 0;
    margin-top: 25px;
}

.form {
    width: 50%;
}

@media screen and (max-width: 991px) {
    .form {
        width: 80%;
    }
}

@media screen and (max-width: 575px) {
    .form {
        width: 90%;
    }
}
